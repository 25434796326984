<template>
  <div class="container">
    <!-- <div class="debug">width {{ w }} height {{ h }}</div> -->
    <div class="content">
      <header>
        <div class="logo">
          <img :src="logo" alt="ymly art logo" width="50%" height="100%" />
        </div>
      </header>

      <div class="main">
        <div class="gallery">
          <div
            class="artwork slide-fade-enter-active"
            v-for="item in slides"
            :key="item.message"
            v-bind:class="classObject"
          >
            <img :src="item" alt="art" />
          </div>
        </div>
        <section>
          <div class="welcome" v-bind:class="classWelcomeObject">{{message}}</div>
        </section>
        <button>
          <router-link to="/virtual-gallery">Launch Gallery</router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

const { IsMobile } = require("../utils/mobile");

exports = module.exports = {
  data() {
    return {
      isActive: true,
      w: 0,
      h: 0,
      hasError: true,
      isMobile: false,
      logo: "/img/ymly-art-logo-copy-2.svg",
      background: "/img/background.png",
      slides: ["/img/1.png", "/img/2.png", "/img/3.png", "/img/4.png"],
      message:
        "Welcome to the second annual VMYY&ART show, a place where people who are good at stuff that the agency might not know about can raise some eyebrows by making some cool shit."
    };
  },
  computed: {
    classObject: function() {
      this.isMobile = IsMobile();
      return {
        "artwork-mobile": this.isMobile
      };
    },
    classWelcomeObject: function() {
      this.isMobile = IsMobile();
      return {
        "welcome-mobile": this.isMobile
      };
    }
  },
  mounted() {
    isMobile = IsMobile();
    console.log("is mobile", isMobile);
    this.w = window.innerWidth;
    this.h = window.innerHeight;
  }
};
</script> 

<style lang="scss" >
/**
 * Vars
 */

$font-stack: "Roboto-Medium", Helvetica Neue, Helvetica, Arial, sans-serif;

.content {
  background: url("../../public/img/background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: block;
  overflow: hidden;
  padding: 1em auto;
  // border: 2px solid red;
  height: 100%;
  overflow: hidden;
}
.logo {
  height: 8%;
  // border: 2px solid red;
  padding-bottom: 2em;
}
.gallery {
  display: flex;
  align-items: center;
  // border: 1px solid red;
  //  overflow-x: scroll;
  // overflow: hidden;
}

section {
  // border: 1px solid white;
  // display: inline-block;
  width: 80%;
  // height: 100%;
  padding: 0 10%;
  display: flex;
}

.artwork {
  position: relative;
  padding: 0 auto;
  left: -5%;
  height: 70%;
  img {
    padding: 0 4vw;
    width: 18vw;
    height: auto;
    // border: 20px solid red;
  }
}
.artwork-mobile {
  position: relative;
  padding: 0 auto;
  // left: -10%;
  height: auto;
  align-content: center;
  @media (min-width: 400px) {
    // border: 2px solid red;
    left: -22vh;
    img {
      padding: 1vh 4em;
      width: auto;
      height: 40%;
    }
  }
  @media (min-width: 981px) {
    // border: 2px solid blue;
    left: -20vh;
    img {
      padding: 0 4em;
      width: auto;
      height: 40%;
    }
  }
  @media (min-width: 1280px) {
    // border: 2px solid white;
    // left: -20em;
    img {
      padding: 1em 4em;
      width: auto;
      height: 50%;
    }
  }
  // float: left;
  // align-items: center;
  // align-content: flex-end;
  // border: 2px solid white;
}

.welcome {
  // border: 1px solid cyan;
  font-weight: 600;
  text-transform: uppercase;
  // min-width: 70%;
  width: 100%;
  padding: 1rem 1em;
  font-family: $font-stack;
  font-weight: 400;
  // font-stretch: condensed;
  // font-style: normal;
  font-size: .7em;
  line-height: 1em;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media (min-width: 500px) {
    // border: 1px solid red;
    padding: .5em 2em;
    font-size: .8em;
    line-height: 1.4em;
  }
  @media (min-width: 1430px) {
    // border: 1px solid blue;
    padding: 3vh 30vh;
    font-size: 0.7em;
    line-height: 1.2em;
  }
  @media (min-width: 1690px) {
    // border: 1px solid white;
    padding: 1em 8em;
    font-size: 1em;
    line-height: 1.2em;
  }
  @media (min-width: 2400px) {
    // border: 1px solid yellow;
    padding: 2em 18em;
    font-size: 1.4em;
    line-height: 1.8em;
  }
}
.welcome-mobile {
  // border: 1px solid cyan;
  font-weight: 600;
  text-transform: uppercase;
  // min-width: 70%;
  width: 100%;
  // padding: 2rem 12em;
  // height: 2em;
  font-family: $font-stack;
  font-weight: 400;
  // font-stretch: condensed;
  // font-style: normal;
  font-size: 1.2em;
  line-height: 1.3em;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media (min-width: 500px) {
    padding: 2vh 10em;
    font-size: 1em;
    line-height: 1.5em;
    // border: 1px solid white;
  }
  @media (min-width: 981px) {
    padding: 2vh 4vh;
    font-size: 1.6em;
    line-height: 2em;
    // border: 1px solid red;
  }
  @media (min-width: 1400px) {
    padding: 2em 4em;
    font-size: 1.2em;
    line-height: 1.6em;
    // border: 1px solid blue;
  }
  @media (min-width: 2400px) {
    padding: 2em 24em;
    font-size: 1.4em;
    line-height: 1.8em;
    // border: 1px solid yellow;
  }
}

button {
  font-family: $font-stack;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  width: 189px;
  height: 57px;
  border-radius: 4px;
  background-color: #ffffff;
  color: black;
  a {
    color: black;
    text-decoration: none;
  }
}
</style>
