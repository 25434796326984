<template>
  <a-scene>
    <a-assets>
      <a-asset-item id="nook-obj" src="../../Nook.obj"></a-asset-item>
      <a-asset-item id="trees-glb" src="../../trees.glb"></a-asset-item>
      <a-asset-item id="bottle" src="../../SpecGlossVsMetalRough.gltf"></a-asset-item>
      <a-asset-item id="fence" src="../../scene.gltf"></a-asset-item>

      <!-- TEXTURES  -->
      <img id="texture" src="../../public/frame-texture.png" />
      <img id="artwork1" src="../../public/01-mona-lisa.jpg" />
      <img id="artwork2" src="../../public/02-u-ltima-cena-da-vinci.jpg" />
      <img id="artwork3" src="../../public/03-van-gogh-starry-night.jpg" />
      <img id="artwork4" src="../../public/04-edvard-munch.jpg" />
      <img id="artwork5" src="../../public/05-meisje-met-de-parel.jpg" />
      <img id="artwork6" src="../../public/06-guernica.jpg" />
      <img id="artwork7" src="../../public/07-sandro-botticelli-la-nascita-di-venere.jpg" />
      <img id="artwork8" src="../../public/08-the-night-watch.jpg" />
      <img id="artwork9" src="../../public/09-persistenceofmemory.jpg" />
      <img id="artwork10" src="../../public/10-whistlers-mother-high-res.jpg" />

      <video id="big-bucky" autoplay loop="true" src="../../public/bucky.mp4"></video>
      <audio id="morning-dew" src="../../public/Morning_Dew.mp3" preload="auto" loop></audio>
      <audio id="cissy-strut" src="../../public/Cissy_Strut_Edit.mp3" preload="auto" loop></audio>

      <script id="level-2" type="text/html">
  <!-- <a-entity gltf-model="#trees-glb" scale=".5 .5 .5"  position="-8 .5 -8"></a-entity> -->


  <!-- <a-entity gltf-model="#bottle" scale="7 7 7"  position="-8 3 -20"></a-entity> -->
  <!-- <a-box color="white" position="-8 1 -20" scale="2 2 2" ></a-box> -->

      <a-entity id="sound-box" geometry="primitive: box" material="color: blue" sound="src: #cissy-strut; autoplay: true"  position="10 2 10">
  <a-entity obj-model="obj: #nook-obj;" position="0 0 0"></a-entity>
      </a-entity>
      <a-entity id="sound-box" geometry="primitive: plane" material="color: red" sound="src: #morning-dew; autoplay: true"  position="-10 6 -22">
        <a-video src="#big-bucky" width="16" height="9" position="0 0 1"></a-video>
        <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 -5 8" rotation="0 0 0"></a-entity>
      </a-entity>
    <a-entity position="0 0 -35">
      <a-box src="#artwork1" position="0 2 -5" rotation="45 45 45" scale="2 2 2" animation="property: object3D.position.y; to: 2.2; dir: alternate; dur: 2000; loop: true"></a-box>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 4" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork2" height="3" width="3" rotation="0 -90 0" position="4 3 4"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 8" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork3" height="3" width="3" rotation="0 -90 0" position="4 3 8"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 16" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork4" height="3" width="4" rotation="0 -90 0" position="4 3 16"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 24" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork5" height="3" width="4" rotation="0 -90 0" position="4 3 24"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 28" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork6" height="3" width="4" rotation="0 -90 0" position="4 3 28"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 32" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork7" height="3" width="4" rotation="0 -90 0" position="4 3 32"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 36" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork8" height="3" width="4" rotation="0 -90 0" position="4 3 36"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 40" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork9" height="3" width="4" rotation="0 -90 0" position="4 3 40"></a-plane>
      <a-entity gltf-model="#fence" scale=".005 .005 .005"  position="3 0 44" rotation="0 -90 0"></a-entity>
      <a-plane src="#artwork10" height="3" width="4" rotation="0 -90 0" position="4 3 44"></a-plane>
    </a-entity>
      </script>
    </a-assets>

    <a-entity environment="preset: forest; seed: 2; shadow: true;" />

    <a-entity template="src: #level-2"></a-entity>

    <a-entity
      camera="active: true;"
      look-controls
      wasd-controls
      position="0 1.6 0"
      reverseMouseDrag
    >
      <a-entity
        cursor="fuse: true; fuseTimeout: 500"
        position="0 0 -1"
        geometry="primitive: ring; radiusInner: 0.02; radiusOuter: 0.03"
        material="color: black; shader: flat; opacity: 0.3"
      />
    </a-entity>
    <div id="marker"></div>
    <div id="mesh"></div>
  </a-scene>
</template>



<script>
const { THREE } = require("aframe");

const classics = [
  "artwork1",
  "artwork2",
  "artwork3",
  "artwork4",
  "artwork5",
  "artwork6",
  "artwork7",
  "artwork8",
  "artwork9",
  "artwork10"
];

exports = module.exports = {
  data() {
    return {
      access_token:
        "IGQVJVU3RtNGRVLTduQ0NoU3d4VjZAibWItX1poNWRuZAmNpSkFFNjd6NnpFQXVaWlI1OFJYS09heVBsQUhiTEh5NVBqZAmpmS3h3cHpvX18zem03dDU2WVM4V2psWVh1MTF3UmpHWE9R",
      url:
        "https://graph.instagram.com/17841400460890340/media?fields=id,media_url,username,timestamp,caption,media_type,permalink,thumbnail_url",
      username: "rhysturner",
      grams: [],
      next_url: "",
      error: false
    };
  },
  props: {},
  computed: {
    instapage() {
      return "https://www.instagram.com/" + this.username;
    }
  },
  methods: {
    start() {
      console.log("start gallery", document.querySelector("a-scene").object3D);
    },
    getGrams() {
      console.log("getGrams");
      axios
        .get(this.url + "&access_token=" + this.access_token)
        .then(({ data }) => {
          this.grams = data.data;
          console.log(this.grams);
          this.username = data.data[0].username;
          this.next_url = data.paging.next;
          console.log("this.next_url", this.next_url);
          this.createRooms();
        })
        .catch(function(error) {
          console.log(error);
          this.error = true;
        });
    },
    getMoreGrams() {
      console.log("this.next_url", this.next_url);
      axios
        .get(this.next_url)
        .then(({ data }) => {
          this.grams = this.grams.concat(data.data);
          this.next_url = data.paging.next;
        })
        .catch(function(error) {
          console.log(error);
          this.error = true;
        });
    },
    createRooms() {
      console.log("createRooms");
      let i;
      for (i = 0; i < 5; i++) {
        // text += "The number is " + i + "<br>";
        console.log("createRooms", i, this.grams[i].media_url);
        this.createArtwork(i, this.grams[i].media_url);
      }
    },
    createArtwork(i, url) {
      var texture = new THREE.TextureLoader().load(url);
      var material = new THREE.MeshBasicMaterial({ map: texture });
      var geometry = new THREE.BoxGeometry(i * 4, 1, 1);
      document
        .querySelector("a-scene")
        .object3D.setObject3D("mesh", new THREE.Mesh(geometry, material));
      // let meshViss = document.getElementById("mesh");
    },
    classicsGallery() {
      for (i = 0; i < 5; i++) {}
    }
  },
  mounted() {
    // this.start();
  },
  created() {
    // this.getGrams();
  }
};
</script>
